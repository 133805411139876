import React from 'react'
import error from '../assets/error.png';


const ErrorPage = () => {
  return (
    <div>
      <img src = {error} className='w-full '/>
    </div>
  )
}

export default ErrorPage
